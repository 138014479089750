
import { useParams } from 'react-router-dom';

export function withParams(Component:any) { // pass props & params to class component from router
  return (props: any) => <Component {...props} params={useParams()} />;
}

export function JWTDecode(token:string|undefined|null) { // get payload data from jwt token
  return token ? (JSON.parse(window.atob(token.split('.')[1]))) : {}
}

function pad (value:any) {
  return ('00'+value).slice(-2)
}

export function dateFormat(value:any):string{
  let d = new Date(value);
  return `${pad(d.getDate())}-${pad(d.getMonth()+1)}-${pad(d.getFullYear())}`
}
export function dateTimeFormat(value:any):string{
  let d = new Date(value);

let day = d.getDate();
let month = d.getMonth()+1;
let year = d.getFullYear();
let hours = d.getHours();
let minutes = d.getMinutes();

  if(isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)){
    return ""
  }else{
    return `${pad(day)}-${pad(month)}-${year} ${pad(hours)}:${pad(minutes)}`
  }
}

export function uppercaseFirst(str:string) {
  return str[0].toUpperCase() + str.substring(1)
}

export const UCFirst = uppercaseFirst

export function sortByNumberKey(key:string,a:any,b:any){
  return parseInt(a[key], 10) - parseInt(b[key], 10);
}

export function sortByStringKey(key:string,a:any,b:any){
  return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
}

type SortOrder = "ASC" | "DESC"

export function propSort(property:string, sortOrder?: SortOrder, sprop?:string) { // returns comparison function for Array.sort(propSort("key"))
  let result;
  let sort = sortOrder === "ASC" ? 1 : -1
  return function (a:any,b:any) {
      if(sprop){
          result = (a[property][sprop] < b[property][sprop]) ? -1 : (a[property][sprop] > b[property][sprop]) ? 1 : 0;
      }else{
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      }
      return result * sort;
  }
}


