import React from 'react';
import { Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { withParams } from '../../helpers';

interface Props {
    title: string
    children: React.ReactElement
    callback?: Function
    active: boolean
}

interface State { 
    active?: boolean
}

// Confirmation modal - can be used to confirm any action
// Usage: <ModalConfirm title="Are you sure?" active={true} callback={this.callback}>Are you sure you want to delete this item?</ModalConfirm>
// callback is optional, if not provided the modal will just close
// callback will be called with a boolean value, true if the user clicked yes, false if the user clicked no. (or closed the modal)
// active is a boolean that toggles the modal
// title is the title of the modal
// children/innercontent is the content of the modal
class ModalConfirm extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    
    this.state = { active : false }
    this.answer = this.answer.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if(this.props.active !== prevProps.active){
      this.setState({active: this.props.active})
    }
  }

  answer(answer: boolean){
    this.setState({active: false})

    if(this.props.callback){
        this.props.callback(answer)
    }
  }

  render() {
    return <>
        <Modal show={this.state.active} onHide={() => this.answer(false) }>
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <p>{this.props.children}</p>
          </Modal.Body>
  
          <Modal.Footer >
            <Button variant="secondary" onClick={e => this.answer(false)} >Nee</Button>
            <Button variant="primary" onClick={e => this.answer(true)} >Ja</Button>
          </Modal.Footer>
        </Modal>
    </>
  }
}

export default withParams(ModalConfirm)

