import React, { ReactNode } from 'react';
import { Card, Alert, Form, Button, Stack } from 'react-bootstrap';
import { withParams } from '../helpers';
import { UploadTable } from '../models';
import { DefaultEditor } from 'react-simple-wysiwyg';
import ModalConfirm from "./components/ModalConfirm"

import DateTimePicker  from 'react-datetime-picker'
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileDrop } from 'react-file-drop'

const APIClient = new API();

interface Parameters { id: number; }
interface Props { params: Parameters; }
interface State { 
  title?: string
  text?: string
  date?: any
  label?: string
  id?: number
  images?: Array<UploadTable>
  validated?:boolean;
  error?:string; 
  toggleDeleteDialog?: boolean;
  imageReplaceDialog?: boolean;
  imageID?: number;
  uploadReceived?: boolean;
}

class NewsItem extends React.Component <Props, State> {
  file: any;

  constructor(props: Props) {
    super(props);
    this.state = { title : "", text : "", date : new Date(), label : "" }

    this.handleUpload = this.handleUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.toggleImageReplaceModal = this.toggleImageReplaceModal.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(){
    if(this.props.params.id){
      APIClient.newsitem(this.props.params.id).then((data) => {
        if(data.images) {
          data.images = data.images.length ? data.images : [data.images]
          this.setState({imageID: data.images[0].id})
        }
        else {
          this.setState({ imageID: undefined})
        }
        let date = data.date && data.date !== "0000-00-00 00:00:00" ? new Date(data.date) : new Date()

        this.setState({ id: data.id, title: data.title, text: data.text, images: data.images , date: date, label: data.label })
      }).catch((e) => {
        window.location.href = "./login/2"
      })
    }
  }

  handleChange(event:any, key?:string){
    if(event && event.target){
      let value = event.target.value;
      let name = event.target.name;
      if(name || value){
        this.setState({[name]:value})
        return;
      }
    }else{
      if(key && event) {
        this.setState({[key]:event})
      }
    }
  }

  handleUpload(event?:any) {
    let file; 

    if(!event || !event.target.files || event.target.files.length === 0){
      file = this.file ? this.file : undefined // if event is undefined we might have stashed it for confirmation
    }else{
      event.preventDefault();
      file = event.target.files[0]
    }
    
    let data = {
      title : "test upload",
      text: "test",
      file : file
    }

    if(file){
      if(this.state.imageID) {this.deleteImage(this.state.imageID)}
      this.setState({imageReplaceDialog: false, toggleDeleteDialog: false, uploadReceived: true})
      APIClient.upload("news", this.state.id ? this.state.id : 0, data).then((resp) => {
        this.fetchData()
      })

    }

  }

  saveNewsItem() {
    let { title, text, id, date } = this.state

    title = title ? title : ""
    text = text ? text : ""

    if (id){
      return APIClient.updateNews({ id:id, text:text, title:title, label:"", date: new Date(date).toISOString().slice(0, 19).replace('T', ' ')})
      
    }else{
      return APIClient.addNews({ text:text, title:title, label:"", date: new Date(date).toISOString().slice(0, 19).replace('T', ' ')}).then((event) => {
        window.location.href = `${APIClient.path}/nieuwsitem/${event.id}`
      })
    }
  }

  handleSubmit(event:any) {

    let { title, text } = this.state

    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if(title && text){
      this.saveNewsItem().then((resp:any) => {
        this.setState({validated:true, error: undefined})
        
        if(this.state.id){
        window.location.href = `${APIClient.path}/nieuws`
        }
      }).catch((error) => {
        this.setState({error:"Er ging iets mis tijdens het opslaan."})
      })
    }else{
      this.setState({error:"Verplichte velden"})
    }

  }

  deleteImage(id?:number){
    if(!id){return}
    if(id){
      this.setState({uploadReceived: false})
      this.saveNewsItem().then(() => {
        APIClient.deleteUpload(id as number).then(() => {
          this.fetchData();
          this.setState({toggleDeleteDialog: false})
        })
      })
    }
  }

  toggleDeleteDialog(imageId:number){
    this.setState({toggleDeleteDialog: true, imageID: imageId})
  }

  toggleImageReplaceModal(event: any) : void{
    event.preventDefault(); // prevent browser from reloading and posting form
    // save file in state so we can handle it later
    this.file = event ? event.target.files[0] : undefined
    this.setState({imageReplaceDialog: event ? true : false, toggleDeleteDialog: false})
  }

  render() {

    let Uploads: Array<ReactNode> = []
    if(this.state.id && this.state.images) {
        this.state.images.forEach((image, index) => {
            if(image.id && image.id !== undefined && !isNaN(image.id)){
            let path = `${APIClient.website}/${image.path}` 
            let id = image.id


            Uploads.push(<Card key={index} style={{maxWidth:"10vw"}}>
            <a href={path} target="_blank" rel="noreferrer"><Card.Img variant="top"src={path}  style={{maxWidth:"100%"}} /></a>
            <Card.Body>
              <Button variant="danger" size="sm" onClick={() => {this.toggleDeleteDialog(id)}}> <FontAwesomeIcon icon="trash" /><b>&nbsp;Delete</b> </Button>
            </Card.Body>
            </Card>)
            }
        })
    }


    let {toggleDeleteDialog, imageID, imageReplaceDialog} = this.state

    return <FileDrop onDrop={(files, event) => { let e:any = { target:{files:files}}; this.handleUpload(e)}}>
      <Card>

            <ModalConfirm title="Foto vervangen" active={imageReplaceDialog} callback={(val:boolean) => { if(val === true){ this.handleUpload() } else {this.setState({imageReplaceDialog: false})} }}>Bestaande foto vervangen?</ModalConfirm>
            <ModalConfirm title="foto vervangen" active={toggleDeleteDialog} callback={(val:boolean) => { if(val === true){ this.deleteImage(imageID) } else {this.setState({toggleDeleteDialog: false, uploadReceived: false})}}}>Wilt U de foto verwijderen?</ModalConfirm>

      <Card.Header>Nieuws item : {this.state.title}</Card.Header>
        <Card.Body>
          { this.state.validated && <Alert variant='success'>Wijzigingen succesvol opgeslagen!</Alert> }
          { this.state.error && <Alert variant='danger'>{this.state.error}</Alert> }
          { this.state.uploadReceived && <Alert variant='success'>Foto succesvol opgeslagen!</Alert>}
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Titel</Form.Label>
              <Form.Control type="title" onChange={this.handleChange} value={this.state.title} name="title" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Datum</Form.Label><br />
              <DateTimePicker onChange={(e) => {this.handleChange(e, "date")}} value={this.state.date} format="dd-MM-yyyy HH:mm" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Text</Form.Label>
              <DefaultEditor name="text" onChange={this.handleChange} value={this.state.text} style={{minHeight:"25vh"}} />
            </Form.Group>
            
            { this.state.id && <><Form.Group> 
              <Form.Label>Upload</Form.Label>
              {imageID &&  <Form.Control type="file" value="" onChange={e => this.toggleImageReplaceModal(e)}/>}
              {!imageID && <Form.Control type="file" value="" onChange={e => this.handleUpload(e)}/>}
            </Form.Group>
            <Form.Group>
              <Stack direction="horizontal" gap={2}>
              { Uploads }
              </Stack>
            </Form.Group></>
            }

            <br />
            <Button variant="primary" type="submit" onClick={this.handleSubmit}><FontAwesomeIcon icon="floppy-disk" /> <b>&nbsp;Opslaan</b></Button>
          </Form>
        </Card.Body>
    </Card>
        </FileDrop>
  }
}



export default withParams(NewsItem)