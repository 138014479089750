
import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { dateTimeFormat, withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteConfirmButton from './components/DeleteConfirmButton';

const APIClient = new API();

interface Props { params:any; }
interface State { 
  news?: Array<any>; 
  sortKey: string; 
  sortDirection: 'asc' | 'desc';
}

class News extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      sortKey: 'date', // default sort key
      sortDirection: 'desc' // default sort direction
    }

    this.getData = this.getData.bind(this)
    this.delete = this.delete.bind(this)
    this.sortNews = this.sortNews.bind(this)
  }


  sortNews(a: any, b: any) {
    if (a[this.state.sortKey] < b[this.state.sortKey]) {
      return this.state.sortDirection === 'asc' ? -1 : 1;
    }
    if (a[this.state.sortKey] > b[this.state.sortKey]) {
      return this.state.sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  }

  componentDidMount() {
    this.getData();
  }

  getData(){

    APIClient.news().then((data) => {
      console.log(data)
      this.setState({ news:data })
    }).catch((e) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
    if(!id) return alert("Geen id opgegeven")
      APIClient.deleteNews(id).then(() => {
        this.getData(); 
      }) 
  }

  render() {
    if(!this.state.news) {
      return <></>
    }


    this.state.news.sort(this.sortNews);


    let rows:Array<any> = []
    this.state.news.forEach((newsItem,index) => { 
      rows.push(<tr key={index}>
        <td>{newsItem.id}</td>
        <td><a href={`${APIClient.website}/nieuws-item/${newsItem.id}`}>{newsItem.title}</a></td> 
        <td>{dateTimeFormat(newsItem.date)}</td> 
        <td>{newsItem.feed ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="x" />}</td>
        <td> 
          <a href={`${APIClient.path}/nieuwsitem/${newsItem.id}`}>
          <Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
          <DeleteConfirmButton callback={() => { this.delete(newsItem.id) }} />
        </td>
      </tr>)
    })



    return  <Card>
        <Card.Header><FontAwesomeIcon icon="newspaper" /> &nbsp;Nieuws overzicht
          <a href="/nieuwsitem"><Button variant="success" size="sm" style={{float:"right"}}><FontAwesomeIcon icon="plus" /> Toevoegen </Button></a>
        </Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th onClick={() => this.setState({ sortKey: 'date', sortDirection: this.state.sortDirection == "asc" ? "desc" : "asc" })}>Titel</th>
                <th onClick={() => this.setState({ sortKey: 'date', sortDirection: this.state.sortDirection == "asc" ? "desc" : "asc" })} >Datum</th>
                <th>Ditisonzewijk</th>
                <th style={{width:"200px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { (rows && rows.length) && rows }
              { (!rows || !rows.length) && <>Geen data</> }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(News)