
import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { UCFirst, withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const APIClient = new API();

interface Props { params:any; }
interface State { pages?: Array<any> }

class Pages extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}

    this.getData = this.getData.bind(this)
    this.delete = this.delete.bind(this)
  }


  componentDidMount() {
    this.getData();
  }

  getData(){
    APIClient.pages().then((data) => {
      console.log(data)
      this.setState({ pages:data })
    }).catch((e) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
    if(window.confirm("Weet u het zeker?")){ 
      APIClient.deletePage(id as number).then(() => {
        this.getData(); 
      }) 
    }
  }

  render() {
    if(!this.state.pages) {
      return <></>
    }


    let rows:Array<any> = []
    this.state.pages.forEach((page,index) => { 
      rows.push(<tr key={index}>
        <td>{page.id}</td>
        <td><a href={`${APIClient.website}/${page.title}`}>{UCFirst(page.title)}</a></td> 
        <td> 
          <a href={`${APIClient.path}/pagina/${page.id}`}>
          <Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
          {/* <Button variant="danger" size="sm" onClick={() => { if(window.confirm("Weet u het zeker?")){ APIClient.deletePage(page.id); this.getData(); } }}> <FontAwesomeIcon icon="trash-can" /> </Button> */}
        </td>
      </tr>)
    })



    return  <Card>
        <Card.Header><FontAwesomeIcon icon="file" /> &nbsp;Pagina overzicht</Card.Header>
        <Card.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th>Titel</th>
                <th style={{width:"100px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { (rows && rows.length) && rows }
              { (!rows || !rows.length) && <>Geen data</> }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(Pages)