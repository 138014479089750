import React, { ReactNode } from 'react';
import { Card, Button, Form, CardGroup, Row, Col } from 'react-bootstrap';
import API from '../../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withParams } from '../../helpers';
import { UploadTable } from '../../models';
import { upload } from '@testing-library/user-event/dist/upload';

const APIClient = new API();

interface Props {
    title: string;
    children: React.ReactElement;
    images?: Array<UploadTable> |undefined;
    image?: UploadTable;
    callback?: Function;
}

interface State { 
    active?: boolean;
    images?: Array<UploadTable> | undefined;
    image?: UploadTable;
    titel?: string;
    text?: string;
}

class ImageCard extends  React.Component <Props, State> {

    constructor(props: Props) {
      super(props);

      this.state = {text: "", titel: "", images: undefined}

      this.handleChange = this.handleChange.bind(this)
    }
    
    /*
    Callback output 1: changed rows. Array/Uploadtable || undefined
    Callback output 2: delete UploadTable Id. number || undefined
    */

    componentDidMount(): void {
        // receives imagetable from parent and converts it to state for changes.
        if(this.props.images){
            const images = this.props.images
            this.setState({images})
        }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.images && prevState.images){
            if(prevState.images.length !== this.props.images.length){
                this.setState({images: this.props.images})
            }
        }
    }

    handleChange(event:any, index:number){
        let img = this.state.images
        let value = event.target.value;
        let name = event.target.name;
        if(name === "title" && img){
            img[index].title =  value
            this.setState({images: img})

            if(this.props.callback){
                this.props.callback(img)
            }
        }
        if(name === "text" && img ){
            img[index].text = value
            this.setState({images:img})
            if(this.props.callback){
                this.props.callback(img)
            }
        }
      }
      deleteButton(event:any, imageIndex: number){
        event.preventDefault();
        if(imageIndex && this.props.callback){
            this.props.callback(undefined, imageIndex)
        }
    }


    render(){
            
        let Uploads: Array<ReactNode> = [];
        if(this.state.images){
            let images:any = this.state.images


            if(images && images.length > 0){
                images.forEach((image:UploadTable, index:number) => {
                    if (image.id && image.id !== undefined && !isNaN(image.id)){
                    let id = image.id
        
                    let path = `${APIClient.website}/${image.path}` 
                    Uploads.push(
                        <Col>
                        <Card key={index} style={{ minWidth:"170px"}}>
                        <a href={path} target="_blank" rel="noreferrer">
                            <Card.Img variant="top" src={path} style={{maxWidth:"100%", minWidth:"150px"}} />
                        </a>
                        <Card.Body style={{padding:"6px"}}>
                            <Form.Group style={{marginBottom:"6px"}}>
                                {this.state.images && <Form.Control size='sm' placeholder='Foto Titel' type="text" key={index} value={this.state.images[index].title} onChange={e => {this.handleChange(e, index)}} name="title" required />}
                            </Form.Group>
                            <Form.Group style={{marginBottom:"6px"}}>
                                {this.state.images && <Form.Control size='sm' placeholder='Foto Beschrijving' as="textarea" type="text" key={index} value={this.state.images[index].text} onChange={e => {this.handleChange(e, index)}} name="text" />}
                            </Form.Group>
                            
                            <Button className="float-end" variant="danger" size="sm" onClick={(e) => { this.deleteButton(e, id)}}> <FontAwesomeIcon icon="trash" /></Button>
                        </Card.Body>
                    </Card>
                    </Col>)
                    }
                })
            }
        };

    return  (<>
            <Row xs={1} md={3} lg={6}className="g-4">
                {Uploads}
            </Row>
    </>
    )}
}

export default withParams(ImageCard)