import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { withParams } from '../../helpers';

interface Props {
    children: React.ReactElement
    callback?: Function

    text: string
}

interface State { 
    text?: string | React.ReactElement
}

class DeleteConfirmButton extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = { text:"" }

    this.start = this.start.bind(this)
    this.reset = this.reset.bind(this)
    this.confirm = this.confirm.bind(this)
  }

  start(){ 
    if(this.state.text === "Bevestig"){
      this.confirm()
    }else{
      if(this.state.text === ""){
      this.setState({ text : <Spinner animation="border" variant="dark" size="sm" /> }) 
      setTimeout(() => { this.setState({ text : <Spinner animation="border" variant="dark" size="sm" /> }) }, 1000);
      setTimeout(() => { this.setState({ text : <Spinner animation="border" variant="dark" size="sm" /> }) }, 2000);
      setTimeout(() => { this.setState({ text : "Bevestig"}) }, 3000);
      setTimeout(() => { this.reset() }, 8000);
      }
    }
  }

  reset(){
    this.setState({ text : "" })
  }

  confirm(){
    if(this.props.callback){
      this.props.callback()
    }
    this.reset()
  }

  render() {
    let { text } = this.state
    let variant = text === "Bevestig" || text === "" ? "danger" : "warning"
    
    let icon = text !== "Bevestig" ? <FontAwesomeIcon icon="trash-can" /> : <FontAwesomeIcon icon="dumpster-fire" /> // couldnt help myself

    return <Button variant={variant} size="sm" onClick={() => {this.start()}}> { icon } { text } </Button>
  }
}

export default withParams(DeleteConfirmButton)

