
import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmButton from './components/DeleteConfirmButton';

const APIClient = new API();

interface Props { params:any; }
interface State { albums?: Array<any>  }


class Albums extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}

    this.getData = this.getData.bind(this)
    this.delete = this.delete.bind(this)
  }


  componentDidMount() {
    this.getData();
  }

  getData(){

    APIClient.albums().then((data) => {
      console.log(data)
      this.setState({ albums:data })
    }).catch((e) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
    APIClient.deleteAlbum(id as number).then(() => {
      this.getData(); 
    }) 
  }

  render() {
    if(!this.state.albums) {
      return <></>
    }

    let rows:Array<any> = []
    if(this.state.albums){
      if(this.state.albums.length > 0){
        this.state.albums.forEach((album,index) => { 

          console.log(this.state.albums,"base log")

          if(album.images){
            if(album.images.length === 1 && !album.images[0].title){
              album.images = 0
            }else {
              album.images = album.images.length > 0  ? album.images : [album.images]
            }
          }

          rows.push(<tr key={index}>
            <td>{album.id}</td>
            <td><a href={`${APIClient.website}/album/${album.id}`}>{album.title}</a></td> 
            <td>{ album.images && album.images != 0 ? album.images.length : 0 }</td>
            <td> 
              <a href={`${APIClient.path}/album/${album.id}`}><Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
              <DeleteConfirmButton  callback={() => { this.delete(album.id) }} />
            </td>
          </tr>)
        })
      }
    }
   

    return  <Card>
        <Card.Header><FontAwesomeIcon icon="file" /> &nbsp;Fotoalbum overzicht
          <a href="/album"><Button variant="success" size="sm" style={{float:"right"}}><FontAwesomeIcon icon="plus" /> Toevoegen </Button></a>
        </Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th>Titel</th>
                <th>Afbeeldingen</th>
                <th style={{width:"200px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { (rows && rows.length) && rows }
              { (!rows || !rows.length) && <>Geen data</> }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(Albums)