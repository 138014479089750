import React from 'react';
import { Card, Alert, Form, Button, Stack } from 'react-bootstrap';
import { withParams } from '../helpers';
import { UploadTable } from '../models';
import { DefaultEditor } from 'react-simple-wysiwyg';
import API from '../APIClient';
import ModalConfirm from './components/ModalConfirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageCard from './components/imageCard'

const APIClient = new API();

interface Parameters { id: number; }
interface Props { params: Parameters; }
interface State { 
  title?: string
  text?: string
  label?: string
  id?: number
  images?: Array<UploadTable>
  validated?:boolean;
  error?:string;
  imageUploaded?: boolean;
  toggleDeleteDialog?: boolean;
  
  imageID?: number;
  possibleNewImages?: Array<UploadTable>;
}

class Album extends React.Component <Props, State> {
  imageTitle:any 
  imageText: any

  constructor(props: Props) {
    super(props);
    this.state = { id: this.props.params.id, title : "", text : "" , toggleDeleteDialog: false}

    this.handleUpload = this.handleUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.callBackImageCard = this.callBackImageCard.bind(this)
    this.buttonPress = this.buttonPress.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(){
    if(this.state.id){
      APIClient.album(this.state.id).then((data) => {
        console.log(data)
        if(data.images) { data.images = data.images.length > 0 ? data.images : [data.images] }
        
        this.setState({ id: data.id, title: data.title || "", text: data.text || "", images: data.images })
      }).catch((e) => {
        console.log(e, " error stack")
        // window.location.href = "./login/2"
      })
    }
  }

  handleChange(event:any){
    let value = event.target.value;
    let name = event.target.name;
    if(name || value){
      this.setState({[name]:value})
    }
  }

  handleUpload(event:any) {
    event.preventDefault();
    
    let data = {
      title : event.target.files[0].name,
      text: "",
      file : event.target.files[0]
    }

    APIClient.upload("albums", this.state.id || 0, data).then((resp) => {
      this.fetchData()
      this.setState({imageUploaded: true, toggleDeleteDialog: false, validated: false})
    })


  }

  saveAlbum() {
    let { title, text, id, label } = this.state

    let data = {
      title: title || "",
      text: text || "",
      label: label || "",
      id: id || undefined
    }

    if (id){
      return APIClient.updateAlbum(data).then((album) => {
        this.setState({...album})
      }).catch(console.error)
    }else{
      return APIClient.addAlbum(data).then((album) => {
        this.setState({...album})
      }).catch(console.error)
    }
  }

  async handleSubmit(event:any) {
    event.preventDefault();
    let { title } = this.state

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    
    if(!this.state.id){
      if(title){
        this.saveAlbum().then((resp:any) => {
          this.setState({validated:true, error: undefined})
        }).catch((error) => {
          this.setState({error:"Er ging iets mis tijdens het opslaan."})
        })
      }else{
        this.setState({error:"Verplichte velden"})
      }
    }else{
      if(title){
        this.saveAlbum().then((resp:any) => {
          this.setState({validated:true, error: undefined, ...resp})
      
          window.location.href = `${APIClient.path}/albums` // after a user is done editing, redirect to the albums page
        }).catch((error) => {
          this.setState({error:"Er ging iets mis tijdens het opslaan."})
        })
      }else{
        this.setState({error:"Verplichte velden"})
      }
    }


    
    if(this.state.possibleNewImages){
      await this.updateImageUploads(this.state.possibleNewImages).then(() => {this.setState({validated: true, imageUploaded: false})})
    }
  };
  
  deleteImage(id?:number){
    this.saveAlbum().then(() => {
      APIClient.deleteUpload(id as number).then(() => {
        this.fetchData();
      })
    }) 
    this.setState({imageUploaded: false, toggleDeleteDialog: false})
  }

  toggleDeleteDialog(imageId:number){
    this.setState({toggleDeleteDialog: true, imageID: imageId})
  }
  callBackImageCard(imageArray: Array<UploadTable>, deleteImageId: number){
    if(deleteImageId){
      this.setState({imageID: deleteImageId})
      this.toggleDeleteDialog(deleteImageId);
    }
    if(imageArray){
      this.setState({possibleNewImages: imageArray});
    }
  }

  async updateImageUploads(imageArray: Array<UploadTable>): Promise<void> {
    if(this.state.images && imageArray){
      this.state.images.forEach(async (image, index)=>{
        let imageTitle, imageText;

        //add continue to loop for equal values

        if(image.text !== imageArray[index].text){
          imageText = imageArray[index].text
        } else {imageText = image.text}
        if(image.title !== imageArray[index].title){
          imageTitle = imageArray[index].title
        } else {imageTitle = image.title}

        let data = {
          title : imageTitle,
          text: imageText
        }

        let id =  imageArray[index].id
        if(id && id !== undefined && !isNaN(id)){
          await APIClient.updateUpload(id, data).then((resp) => {
            return resp
          }).catch(e => console.error(e.toJSON(), "log pls"))
        }
      })
    }
  }
  
  buttonPress(event:any){
    if(event.key === "Enter" && this.state.toggleDeleteDialog === true){
      this.deleteImage(this.state.imageID)
    }
    else {return }
  }

  render() {

    let {toggleDeleteDialog, imageID} = this.state

    return <Card onKeyDown={this.buttonPress}>

      <ModalConfirm title="foto vervangen"  active={toggleDeleteDialog} callback={(val:boolean) => { if(val === true){ this.deleteImage(imageID) } else {this.setState({toggleDeleteDialog: false})}}}>Wilt U de foto verwijderen?</ModalConfirm>

      <Card.Header><FontAwesomeIcon icon="file" /> &nbsp;Fotoalbum : {this.state.title}</Card.Header>
        <Card.Body>
          { this.state.imageUploaded && <Alert variant="success">Foto succesvol toegevoegd! </Alert>}
          { this.state.validated && <Alert variant='success'>Wijzigingen succesvol opgeslagen!</Alert> }
          { this.state.error && <Alert variant='danger'>{this.state.error}</Alert> }
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Titel</Form.Label>
              <Form.Control type="title" onChange={this.handleChange} value={this.state.title} name="title" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Text</Form.Label>
              <DefaultEditor name="text" onChange={this.handleChange} value={this.state.text} style={{minHeight:"25vh"}} />
            </Form.Group>
            
            { this.state.id && <><Form.Group>
              <Form.Label>Upload</Form.Label>
              <Form.Control type="file" onChange={this.handleUpload}/>
            </Form.Group>
            <Form.Group >
              <Stack direction="horizontal" gap={2}>
              {(this.state.images && <ImageCard images={this.state.images} callback={this.callBackImageCard} />)}
              </Stack>
            </Form.Group></>
            }

            <br />
            <Button variant="primary" type="submit" onClick={this.handleSubmit}><FontAwesomeIcon icon="floppy-disk" /> <b>&nbsp;Opslaan</b></Button>
          </Form>
        </Card.Body>
    </Card>
  }
}



export default withParams(Album)