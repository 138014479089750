import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './layout/index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './layout/layout';
import Home from './pages/home';
import Page from './pages/page';
import Login from './pages/login';
import Pages from './pages/pages';
import Events from './pages/events';
import Info from './pages/info';
import Rooms from './pages/rooms';
import News from './pages/news';
import Albums from './pages/albums';
import Users from './pages/users';
import Album from './pages/album';
import Event from './pages/event';
import User from './pages/user';
import Room from './pages/room';
import NewsItem from './pages/newsitem';
import InfoItem from './pages/infoitem';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Home />}> </Route>
          <Route path="/login" element={<Login />}> </Route>
          <Route path="/login/:id" element={<Login />}> </Route>
          <Route path="/pagina/:id" element={<Page />}> </Route>
          <Route path="/paginas" element={<Pages />}> </Route>
          <Route path="/agenda" element={<Events />}> </Route>
          <Route path="/agenda/toevoegen" element={<Event />}> </Route>
          <Route path="/agenda/:id" element={<Event />}> </Route>
          <Route path="/info-oost" element={<Info />}> </Route>
          <Route path="/info-oost/:id" element={<InfoItem />}> </Route>
          <Route path="/info-oost/toevoegen" element={<InfoItem />}> </Route>
          <Route path="/info-oost" element={<Info />}> </Route>
          <Route path="/zaalverhuur" element={<Rooms />}> </Route>
          <Route path="/zaal" element={<Room />}> </Route>
          <Route path="/zaal/:id" element={<Room />}> </Route>
          <Route path="/nieuws" element={<News />}> </Route>
          <Route path="/nieuwsitem" element={<NewsItem />}> </Route>
          <Route path="/nieuwsitem/:id" element={<NewsItem />}> </Route>
          <Route path="/albums" element={<Albums />}> </Route>
          <Route path="/album" element={<Album />}> </Route>
          <Route path="/album/:id" element={<Album />}> </Route>
          <Route path="/users" element={<Users />}> </Route>
          <Route path="/user" element={<User />}> </Route>
          <Route path="/user/:id" element={<User />}> </Route>
        </Routes>
      </BrowserRouter>
    </Layout>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
