
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import API from '../APIClient';
import { withParams } from '../helpers';

let APIClient = new API();

interface Parameters { id: string;}
interface Props { params: Parameters; }

interface State {  
  email?: string;
  password?:string;
  validated?:boolean;
  error?:string;
  logoutMessage?: string;
}

// type logoutMessage = 1 | 2 | 3

class Login extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loggedOut = this.loggedOut.bind(this)
  }

  handleChange(event:any){
    let value = event.target.value;
    let name = event.target.name;
    if(name || value){
      this.setState({[name]:value})
    }
  }

  componentDidMount() {
    this.loggedOut();
  };

  handleSubmit(event:any) {

    let {email, password} = this.state

    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if(!email || !password){
      this.setState({validated:false, error : "Gegevens incorrect"})
    }else{

      let credentials = { email:email, password: password }

      APIClient.login(credentials).then((valid:boolean) => {
          if(valid){
            this.setState({validated:true, error: undefined})
          }else{
            this.setState({validated:false, error : "Gegevens incorrect"})
          }
      }).catch((error) => this.setState({validated:false, error : "Er gaat iets mis. Probeer het later opnieuw."}))
    }

  };


  loggedOut(){
    
    let parsed = parseInt(this.props.params.id)
    let messageState = isNaN(parsed) ? 0 : parsed

    let sendLogoutMessage = [
      "Gebruiker is afgemeld.",
      "Uw Sessie is verlopen. De Gebruiker is afgemeld.",
      "Er is iets misgegaan met uw sessie. U moet opnieuw inloggen."
    ]

    if (messageState){
      this.setState({logoutMessage: sendLogoutMessage[messageState-1]})
    }
  }


  render() {

    if(this.state.validated === true) {
      window.location.href = "/"
    }
    let renderLogout = () => {
      let convertParamToNumber = parseInt(this.props.params.id)
      if (convertParamToNumber > 0 && convertParamToNumber <4){
          return true
      }
      else {
        return false
      }
    }

    return <Card >
      <Card.Header><FontAwesomeIcon icon="key" />&nbsp;&nbsp;Login</Card.Header>
        <Card.Body>
          { this.state.validated && <Alert variant='success'>Succesvol ingelogd! U word doorverwezen.</Alert> }
          { !this.state.validated && this.state.error && <Alert variant='danger'>{this.state.error}</Alert> }
          { renderLogout() && <Alert variant='danger'> {this.state.logoutMessage}</Alert>}
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={this.handleChange} name="email" placeholder="Email" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" onChange={this.handleChange} placeholder="Password" required />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit" onClick={this.handleSubmit}><FontAwesomeIcon icon="door-open" />&nbsp;
             Login
            </Button>
          </Form>
        </Card.Body>
    </Card>
  }
}

export default withParams(Login)