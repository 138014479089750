
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import DeleteConfirmButton from './components/DeleteConfirmButton';

interface Props {}
interface State {}

export default class Home extends React.Component <Props, State> {

  render(){

    return <Card>
        <Card.Header><FontAwesomeIcon icon="house" /> &nbsp;Home</Card.Header>
        <Card.Body>
            {/*           
            <ListGroup>
              <ListGroup.Item variant='dark'><b>TODO</b></ListGroup.Item>
              <ListGroup.Item variant="info">Website dubbel checken en netjes maken</ListGroup.Item>
              <ListGroup.Item variant="info">Foto albums</ListGroup.Item>
              <ListGroup.Item variant="danger">Crosspost ditisonzewijk</ListGroup.Item>
              <ListGroup.Item variant="info">Deployment</ListGroup.Item>
            </ListGroup>
            <br />*/}
            {/* <DeleteConfirmButton text="DELETE" callback={() => { alert("hi!") }}></DeleteConfirmButton>  */}
            Welkom op het beheer paneel van wijkhuisdelier.

        </Card.Body>
    </Card>
    
    
  }
}
