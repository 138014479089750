
import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteConfirmButton from './components/DeleteConfirmButton';

const APIClient = new API();

interface Props { params:any; }
interface State { users?: Array<any> }

class Users extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}

    this.getData = this.getData.bind(this)
  }


  componentDidMount() {
    this.getData();
  }

  getData(){

    APIClient.users().then((data) => {
      console.log(data)
      this.setState({ users:data })
    }).catch((e) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
    if(!id) return alert("Geen id opgegeven")
      APIClient.deleteUser(id).then(() => {
        this.getData(); 
      }) 
  }
  render() {
    if(!this.state.users) {
      return <></>
    }

    let rows:Array<any> = []
    if(this.state.users && this.state.users.length){
      for (let index = 0; index < this.state.users.length; index++) {
        const user = this.state.users[index];
        
        rows.push(<tr key={index}>
          <td>{user.id}</td>
          <td>{user.email}</td> 
          <td>{user.userType}</td> 
          <td> 
            <a href={`${APIClient.path}/user/${user.id}`}>
            <Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
            { APIClient.userType === "admin" && <DeleteConfirmButton callback={() => this.delete(user.id)} /> }
          </td>
        </tr>)
      }
        
    }



    return  <Card>
        <Card.Header><FontAwesomeIcon icon="user" /> &nbsp;Gebruikersbeheer
          {/* <a href="/user"><Button variant="success" size="sm" style={{float:"right"}}><FontAwesomeIcon icon="plus" /> Toevoegen </Button></a> */}
        </Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th>Gebruikersnaam</th>
                <th>Type</th>
                <th style={{width:"200px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { rows }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(Users)