
import React from 'react';
import { Container, Nav, Navbar, Stack, ThemeProvider } from 'react-bootstrap';
import API from '../APIClient';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas, faTwitter, faFontAwesome)

interface Props { 
    children: React.ReactElement
}

interface State { }

const APIClient = new API();

export default class Layout extends React.Component <Props, State> {

  render(){

    return  <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
                <Container fluid>
                    <Stack gap={1}>
                        
                        {/* NAVBAR */}
                        <Navbar bg="dark" variant="dark" className="header">
                            <Navbar.Brand href={APIClient.website}>Wijkhuisdelier</Navbar.Brand>
                            {APIClient.userType && <>
                                <Nav className="me-auto">
                                    { APIClient.userType && <Nav.Link href="/"><FontAwesomeIcon icon="house" /> &nbsp;Home</Nav.Link> }
                                    { (APIClient.userType === "admin" || APIClient.userType === "agenda") && <Nav.Link href="/agenda"><FontAwesomeIcon icon="calendar-days" /> &nbsp;Agenda</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/paginas"><FontAwesomeIcon icon="file" /> &nbsp;Pagina's</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/albums"><FontAwesomeIcon icon="images" /> &nbsp;Foto Albums</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/nieuws"><FontAwesomeIcon icon="newspaper" /> &nbsp;Nieuws</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/zaalverhuur"><FontAwesomeIcon icon="building" /> &nbsp;Zaalverhuur</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/info-oost"><FontAwesomeIcon icon="file-pdf" /> &nbsp;Info-oost</Nav.Link> }
                                    { APIClient.userType === "admin" && <Nav.Link href="/users"><FontAwesomeIcon icon="user" /> &nbsp;Gebruikers</Nav.Link> }
                                </Nav>
                                <Navbar.Text className="badge">
                                    <Stack gap={0} direction='horizontal'>
                                        <div className='user'>Ingelogd als <strong>{APIClient.userType}</strong><br/>{APIClient.email}</div>
                                        <div className='logout'><a href='/login/1' onClick={APIClient.logout}><FontAwesomeIcon icon={'door-open'} size="2x" /></a></div>
                                    </Stack>
                                </Navbar.Text>
                            </>}
                        </Navbar>
                        <>

                        {/* BODY / CONTENT */}
                        {this.props.children}
                        </>

                    </Stack>



                    {/* FOOTER */}
                    <Navbar fixed="bottom" className="footer">
                        <Nav className="me-auto">
                        </Nav>
                        <Navbar.Text className='float-right'>
                            <a href={APIClient.website}><FontAwesomeIcon icon="globe" size="1x" /></a>
                        </Navbar.Text>
                    </Navbar>
                    
                </Container>
            </ThemeProvider>
    
  }
}
