
import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteConfirmButton from './components/DeleteConfirmButton';

const APIClient = new API();

interface Props { params:any; }
interface State { rooms?: Array<any> }

class Rooms extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}

    this.getData = this.getData.bind(this)
    this.delete = this.delete.bind(this)
  }


  componentDidMount() {
    this.getData();
  }

  getData(){
    APIClient.rooms().then((data) => {
      console.log(data)
      this.setState({ rooms:data })
    }).catch((e) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
      if(!id) return false; // no id given (should not happen)
      APIClient.deleteRoom(id).then(() => {
        this.getData(); 
      }) 
      
  }
  
  render() {

    if(!this.state.rooms) {
      return <></>
    }

    let rows:Array<any> = []
    this.state.rooms.forEach((room,index) => { 
      rows.push(<tr key={index}>
        <td>{room.id}</td>
        <td><a href={`${APIClient.website}/zaalverhuur`}>{room.title}</a></td> 
        <td> 
          <a href={`${APIClient.path}/zaal/${room.id}`}>
          <Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
          <DeleteConfirmButton callback={() => this.delete(room.id)} />
        </td>
      </tr>)
    })



    return  <Card>
        <Card.Header><FontAwesomeIcon icon="building" /> &nbsp;Zaalverhuur overzicht
          <a href="/zaal"><Button variant="success" size="sm" style={{float:"right"}}><FontAwesomeIcon icon="plus" /> Toevoegen </Button></a>
        </Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th>Titel</th>
                <th style={{width:"200px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { (rows && rows.length) && rows }
              { (!rows || !rows.length) && <>Geen data</> }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(Rooms)