import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { dateTimeFormat, withParams } from '../helpers';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteConfirmButton from './components/DeleteConfirmButton';

const APIClient = new API();

interface Props { params:any; }
interface State { events?: Array<any> }

class Events extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {}

    this.getData = this.getData.bind(this)
    this.delete = this.delete.bind(this)
  }

  componentDidMount() {
    this.getData();
  }

  getData(){
    APIClient.events().then((data: any) => {
      console.log(data)
      this.setState({ events:data })
    }).catch((e: any) => {
      window.location.href = "./login/2"
    })
  }

  delete(id?:number){
    if(!id) return false; // console.log("No id given")
      APIClient.deleteEvent(id).then(() => {
        this.getData(); 
      }) 
  }


  render() {
    if(!this.state.events) {
      return <></>
    }


    let rows:Array<any> = []
    this.state.events.reverse().forEach((event,index) => { 
      rows.push(<tr key={index}>
        <td>{event.id}</td>
        <td><a href={`${APIClient.website}/agenda/${event.id}`}>{event.title}</a></td> 
        <td>{dateTimeFormat(event.date)}</td> 
        <td>{dateTimeFormat(event.dateEnd)}</td> 
        <td> 
          <a href={`${APIClient.path}/agenda/${event.id}`}>
          <Button variant="warning" size="sm"> <FontAwesomeIcon icon="pen-clip" /> </Button></a>&nbsp;
          <DeleteConfirmButton callback={() => { this.delete(event.id) }} />
        </td>
      </tr>)
    })



    return <Card>
        <Card.Header><FontAwesomeIcon icon="calendar-days" /> &nbsp;Agenda beheer
          <a href="/agenda/toevoegen"><Button variant="success" size="sm" style={{float:"right"}}><FontAwesomeIcon icon="plus" /> Toevoegen </Button></a>
        </Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th style={{width:"40px"}}>#</th>
                <th>Titel</th>
                <th>Datum</th>
                <th>Eind</th>
                <th style={{width:"200px"}}>Acties</th>
              </tr>
            </thead>
            <tbody><>
              { (rows && rows.length) && rows }
              { (!rows || !rows.length) && <>Geen data</> }
            </>
            </tbody>
          </Table>
        </Card.Body>
    </Card>
  }
}



export default withParams(Events)
