import axios from "axios";
import { JWTDecode } from "./helpers";
import { AlbumTable, EntityTypes, EventTable, MagazinesTable, NewsTable, PageTable, RoomTable, UserTable} from "./models";

interface LoginForm {
  email: string;
  password:string;
}

type UserType = "editor" | "admin" | "agenda"

let { REACT_APP_APIURL, REACT_APP_WEBSITEURL, REACT_APP_ADMINURL } = process.env



export default class API {

    url: string;
    path: string;
    website: string;
    
    payload?:any;
    userType?: UserType;
    email?: string;

    token: string | undefined | null;

    headers: { headers: { authorization: string; }; };
    
    constructor(){
        
        if(!REACT_APP_APIURL || !REACT_APP_WEBSITEURL || !REACT_APP_ADMINURL){
            throw new Error("APIURL, WEBSITEURL or ADMINURL is not set in .env file")
        }

        this.url = REACT_APP_APIURL;
        this.website = REACT_APP_WEBSITEURL;
        this.path = REACT_APP_ADMINURL;
        
        this.token = window.localStorage.getItem("token")
        this.headers =  { headers: { "authorization" : this.token ? this.token : "" } }

        if(!this.token && !window.location.href.includes(`${this.path}/login`)){ // in not loggd in force user to login page
            window.location.href = `${this.path}/login/3`
        }else{
            this.payload = JWTDecode(this.token)
            this.email = this.payload.email
            this.userType = this.payload.userType
        }

    }

    async req(){

    }

    async login(data: LoginForm){

        return axios.post(`${this.url}/auth/login`, data).then((response) => {

            if(response.data.accessToken){
                this.token = response.data.accessToken
                window.localStorage.setItem("token", response.data.accessToken)
                return true
            }else{
                return false
            }
        })
    }

    logout(){
        window.localStorage.removeItem("token")
    }

    pages(){
        return axios.get(`${this.url}/pages`, this.headers).then((response:any) => { 
            console.log("log",response)
            return response.data })
    }

    page(id:number){
        return axios.get(`${this.url}/pages/${id}`, this.headers).then((response:any) => {
            console.log("log",response)
            return response.data
        })
    }

    updatePage(page: PageTable){
        return axios.put(`${this.url}/pages/${page.id}`, page, this.headers).then((response:any) => {
            return response.data
        })
    }

    addPage(page: PageTable){
        return axios.post(`${this.url}/pages`, page, this.headers).then((response:any) => {
            return response.data
        })
    }

    deletePage(id:number){
        return axios.delete(`${this.url}/pages/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    events(){
        return axios.get(`${this.url}/events`, this.headers).then((response:any) => { return response.data })
    }
    
    event(id:number){
        return axios.get(`${this.url}/events/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    addEvent(event: EventTable){
        return axios.post(`${this.url}/events`, event, this.headers).then((response:any) => {
            return response.data
        })
    }

    updateEvent(event: EventTable){
        return axios.put(`${this.url}/events/${event.id}`, event, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteEvent(id:number){
        return axios.delete(`${this.url}/events/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }


    news(){
        return axios.get(`${this.url}/news`, this.headers).then((response:any) => { return response.data })
    }
    
    newsitem(id:number){
        return axios.get(`${this.url}/news/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    addNews(news: NewsTable){
        return axios.post(`${this.url}/news`, news, this.headers).then((response:any) => {
            return response.data
        })
    }

    updateNews(news: NewsTable){
        return axios.put(`${this.url}/news/${news.id}`, news, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteNews(id:number){
        return axios.delete(`${this.url}/news/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }


    info(){
        return axios.get(`${this.url}/magazines`, this.headers).then((response:any) => { return response.data })
    }
    
    infoItem(id:number){
        return axios.get(`${this.url}/magazines/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    addInfo(info: MagazinesTable){
        return axios.post(`${this.url}/magazines`, info, this.headers).then((response:any) => {
            return response.data
        })
    }

    updateInfo(info: MagazinesTable){
        return axios.put(`${this.url}/magazines/${info.id}`, info, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteInfo(id:number){
        return axios.delete(`${this.url}/magazines/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }


    rooms(){
        return axios.get(`${this.url}/rooms`, this.headers).then((response:any) => { return response.data })
    }
    
    room(id:number){
        return axios.get(`${this.url}/rooms/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    addRoom(room: RoomTable){
        return axios.post(`${this.url}/rooms`, room, this.headers).then((response:any) => {
            return response.data
        })
    }

    updateRoom(room: RoomTable){
        return axios.put(`${this.url}/rooms/${room.id}`, room, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteRoom(id:number){
        return axios.delete(`${this.url}/rooms/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }


    users(){
        return axios.get(`${this.url}/users`, this.headers).then((response:any) => { return response.data })
    }
    user(id:number){
        return axios.get(`${this.url}/users/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }
    updateUser(userDetails: UserTable){
        return axios.put(`${this.url}/users`, userDetails, this.headers).then((response:any) => {
            return response.data
        })
    }

    albums(){
        return axios.get(`${this.url}/albums`, this.headers).then((response:any) => { return response.data })
    }
    
    album(id:number){
        return axios.get(`${this.url}/albums/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    addAlbum(album: AlbumTable){
        return axios.post(`${this.url}/albums`, album, this.headers).then((response:any) => {
            return response.data
        })
    }

    updateAlbum(album: AlbumTable){
        return axios.put(`${this.url}/albums/${album.id}`, album, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteAlbum(id:number){
        return axios.delete(`${this.url}/albums/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    upload(type:EntityTypes, parentId: number, data: any){
        let formData = this.formData(data);
        console.log(formData)
        return axios.post(`${this.url}/uploads/${type}/${parentId}`, formData, {...this.headers, ...{ 'Content-Type': 'multipart/form-data' }}).then((response:any) => {
            return response.data
        })
    }
    updateUpload(id:number, data:any){
        return axios.put(`${this.url}/uploads/${id}`, data, this.headers).then((response:any) => {
            return response.data
        })
    }

    fetchUpload(type: EntityTypes, id: number){
        return axios.get(`${this.url}/uploads/${type}/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    deleteUpload(id: number){
        return axios.delete(`${this.url}/uploads/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }
    
    deleteUser(id: number){
        return axios.delete(`${this.url}/users/${id}`, this.headers).then((response:any) => {
            return response.data
        })
    }

    formData(data:any) {
        const formData = new FormData();
        for(let key in data) {
            formData.append(key, data[key])
        }
        return formData
    }

}