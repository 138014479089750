import React, { ReactNode } from 'react';
import { Card, Alert, Form, Button, Stack } from 'react-bootstrap';
import { withParams } from '../helpers';
import { UploadTable } from '../models';
import { DefaultEditor } from 'react-simple-wysiwyg';

import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalConfirm from './components/ModalConfirm';

const APIClient = new API();

interface Parameters { id?: number; }
interface Props { params: Parameters; }
interface State { 
  title?: string
  text?: string
  id?: number
  images?: Array<UploadTable>
  validated?:boolean;
  error?:string;
  imageReplaceDialog?: boolean;
  toggleDeleteDialog?: boolean;
  imageId?: number;
  uploadReceived?: boolean;
}

class Room extends React.Component <Props, State> {
  file: any; 

  constructor(props: Props) {
    super(props);
    this.state = { title : "", text : "" }

    this.handleUpload = this.handleUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.deleteImage = this.deleteImage.bind(this)

  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(){
    if(this.props.params.id){
      APIClient.room(this.props.params.id).then((data) => {
        if(data.images) {
          data.images = data.images.length ? data.images : [data.images]
          this.setState({imageId: data.images[0].id})
        }
        else {
          this.setState({ imageId: undefined})
        }
        this.setState({ id: data.id, title: data.title, text: data.text, images: data.images })
      }).catch((e) => {
        console.log(e)
        window.location.href = "./login/2"
      })
    }
  }

  handleChange(event:any, key?:string){
    if(event && event.target){
      let value = event.target.value;
      let name = event.target.name;
      if(name || value){
        this.setState({[name]:value})
        return;
      }
    }else{
      if(key && event) {
        this.setState({[key]:event})
      }
    }
  }

  handleUpload(event?:any) {
    this.saveRoom();
    let file; 

    if(!event || !event.target.files || event.target.files.length === 0){
      file = this.file ? this.file : undefined // if event is undefined we might have stashed it for confirmation
    }else{
      event.preventDefault();
      file = event.target.files[0]
    }
    
    let data = {
      title : "test upload",
      text: "test",
      file : file
    }
    if(file){
      if(this.state.imageId){this.deleteImage(this.state.imageId)}
      APIClient.upload("rooms", this.state.id ? this.state.id : 0, data).then((resp) => {
        this.fetchData()
        this.setState({imageReplaceDialog: false, toggleDeleteDialog: false, uploadReceived: true})
      })
    }
  }

  saveRoom() {
    let { title, text, id } = this.state
    console.log(this.state)

    if (id){
      return APIClient.updateRoom({ id:id as number, text:text as string, title:title as string})
    }else{
      return APIClient.addRoom({ text:text as string, title:title as string}).then((event) => {
        window.location.href = `${APIClient.path}/zaal/${event.id}`
      })
    }
  }

  handleSubmit(event:any) {

    let { title, text } = this.state

    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if(title && text){
      this.saveRoom().then((resp:any) => {

        this.setState({validated:true, error: undefined})

        if(this.state.id){
          window.location.href = `${APIClient.path}/zaalverhuur`
        }
        
      }).catch((error) => {
        this.setState({error:"Er ging iets mis tijdens het opslaan."})
      })
    }else{
      this.setState({error:"Verplichte velden"})
    }

  }

  deleteImage(id?:number){
    if(!id){ return}
    if(id){
      this.setState({uploadReceived: false})
      this.saveRoom().then(() => {
        APIClient.deleteUpload(id as number).then(() => {
          this.fetchData();
        })
      }) 
      
    }
  }
  toggleImageReplaceModal(event: any) : void{
    event.preventDefault(); // prevent browser from reloading and posting form
    // save file in state so we can handle it later
    this.file = event ? event.target.files[0] : undefined
    this.setState({imageReplaceDialog: event ? true : false, toggleDeleteDialog: false})
  }
  toggleDeleteDialog(event:any){
    event.preventDefault()
    this.setState({toggleDeleteDialog: true, imageReplaceDialog: false})
  }

  render() {

    let Uploads: Array<ReactNode> = []
    if(this.state.id && this.state.images) {
        this.state.images.forEach((image, index) => {
            let path = `${APIClient.website}/${image.path}` 
            Uploads.push(<Card key={index} style={{maxWidth:"10vw"}}>
            <a href={path} target="_blank" rel="noreferrer"><Card.Img variant="top"src={path}  style={{maxWidth:"100%"}} /></a>
            <Card.Body>
              <Button variant="danger" size="sm" onClick={(e) => {this.toggleDeleteDialog(e)}}> <FontAwesomeIcon icon="trash" /><b>&nbsp;Delete</b> </Button>
            </Card.Body>
            </Card>)
        })
    }

    let { imageReplaceDialog, imageId, toggleDeleteDialog } = this.state


    return <Card>
      <ModalConfirm title="Foto vervangen" active={imageReplaceDialog} callback={(val:boolean) => { if(val === true){ this.handleUpload() } else {this.setState({imageReplaceDialog: false})} }}>Bestaande foto vervangen?</ModalConfirm>
      <ModalConfirm title="Foto vervangen" active={toggleDeleteDialog} callback={(val:boolean) => { if(val === true){ this.deleteImage(imageId) } else {this.setState({toggleDeleteDialog: false})}}}>Wilt u de bestaande foto verwijderen?</ModalConfirm>

      <Card.Header><FontAwesomeIcon icon="building" /> &nbsp;Zaalverhuur : {this.state.title}</Card.Header>
        <Card.Body>
          { this.state.validated && <Alert variant='success'>Wijzigingen succesvol opgeslagen!</Alert> }
          { this.state.error && <Alert variant='danger'>{this.state.error}</Alert> }
          { this.state.uploadReceived && <Alert variant='success'> Uw foto is succesvol opgeslagen!</Alert>}
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Titel</Form.Label>
              <Form.Control type="title" onChange={this.handleChange} value={this.state.title} name="title" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Text</Form.Label>
              <DefaultEditor name="text" onChange={this.handleChange} value={this.state.text} style={{minHeight:"25vh"}} />
            </Form.Group>
            
            { this.state.id && <><Form.Group> 
              <Form.Label>Upload</Form.Label>
              {imageId &&  <Form.Control type="file" value="" onChange={e => this.toggleImageReplaceModal(e)}/>}
              {!imageId && <Form.Control type="file" value="" onChange={e => this.handleUpload(e)}/>}
            </Form.Group>
            <Form.Group>
              <Stack direction="horizontal" gap={2}>
              { Uploads }
              </Stack>
            </Form.Group></>
            }

            <br />
            <Button variant="primary" type="submit" onClick={this.handleSubmit}> <FontAwesomeIcon icon="floppy-disk" /> <b>&nbsp;Opslaan</b></Button>
          </Form>
        </Card.Body>
    </Card>
  }
}



export default withParams(Room)