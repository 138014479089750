import React, { ReactNode } from 'react';
import { Card, Alert, Form, Button, Stack} from 'react-bootstrap';
import { withParams } from '../helpers';
import { DefaultEditor } from 'react-simple-wysiwyg';
import API from '../APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalConfirm from './components/ModalConfirm';

const APIClient = new API();

interface Parameters { id: number; } // possible get params
interface Props { params: Parameters; } // add get params to props
interface State { 
  id?: number
  title?: string // form values
  text?: string
  pdf?: string; // pdf path
  pdfID?: number;
  pdfTitle?: string;

  validated?:boolean; // form valid
  error?:string; // error message

  pdfReplaceDialogToggle?: boolean; // pdf replace dialog toggle
  uploadReceived?:  boolean;
  toggleDeleteDialog?: boolean;
}

class InfoItem extends React.Component <Props, State> {

  file:any; // store file for upload

  constructor(props: Props) {
    super(props);

    this.state = { title : "", text : "" }

    this.fetchData = this.fetchData.bind(this)
    this.fetchUploads = this.fetchUploads.bind(this)

    this.handleUpload = this.handleUpload.bind(this)
    this.deleteUpload = this.deleteUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    
    this.togglePdfReplaceModal = this.togglePdfReplaceModal.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  // fetch uploads and set state
  async fetchUploads(){
    if(this.props.params.id){
      let upload = await APIClient.fetchUpload("magazines", this.props.params.id) // fetch related uploads
      upload = upload && upload.length > 0 ? upload[0] : upload // might be an array and we want to make sure we have the first item
      if(upload){
        upload.path = upload.path.startsWith("/") ? `${APIClient.website}${upload.path}` : `${APIClient.website}/${upload.path}` // normalize path
        this.setState({pdf: upload.path, pdfID: upload.id, pdfTitle: upload.title})      
      }else{
        this.setState({pdf: undefined, pdfID: undefined, pdfTitle: undefined}) // clear state since there isn't a pdf      
      }
    }
  }

  // fetch complete state
  fetchData(){
    if(this.props.params.id && !isNaN(this.props.params.id)){
      APIClient.infoItem(this.props.params.id).then((magazine) => { // fetch info item

        this.fetchUploads() // fetch related uploads
        this.setState({id: magazine.id, title: magazine.title, text: magazine.text == undefined ?  "" : magazine.text})      

      }).catch((e) => {
        window.location.href = `${APIClient.website}/login/2`
      })
    }
  }

  // handle form value changes
  handleChange(event:any):void{
    if(event && event.target){
      this.setState({[event.target.name]:event.target.value})
    }
  }

  async handleUpload(event?:any):Promise<void> {
    let file;

    if(!event || !event.target.files || event.target.files.length === 0){
      file = this.file ? this.file : undefined // if event is undefined we might have stashed it for confirmation
    }else{
      event.preventDefault();
      file = event.target.files[0]
    }

    if(file){
      if(this.state.pdfID){ this.deleteUpload(); } // delete old upload if it exists

      let data = { title : "test", text: "test", file : file } // we need to send a title and text to the api 
      await APIClient.upload("magazines", this.state.id ? this.state.id : 0, data) // upload file
      
      this.file = undefined // clear file
      this.setState({ pdfReplaceDialogToggle:false })
      this.fetchUploads()
      this.setState({uploadReceived: true, toggleDeleteDialog: false})
    }
    
  }

  async saveInfoItem():Promise<any> {
    let { title, text, id } = this.state
    if (id){
      if(title){
        return APIClient.updateInfo({ id:id , text: text != undefined ? text: "", title:title})
      }
    }else{
      if(title){
        const event = await APIClient.addInfo({ text: text ? text : text != undefined ? text: "", title: title, label: "Lees meer", date: new Date().toISOString().slice(0, 19).replace('T', ' ') });
        window.location.href = `${APIClient.path}/info-oost/${event.id}`;
      }
    }
  }

  handleSubmit(event:any):void {
    event.preventDefault();
    
    let { title, text } = this.state
    const form = event.currentTarget;

    if (form.checkValidity() === false) { // bootstrap form checks
      event.stopPropagation();
    }else{
      // valid form
      if(title){
        this.saveInfoItem().then((resp:any) => {
          this.setState({validated:true, error: undefined})
          
          if(this.state.id){
            window.location.href = `${APIClient.path}/info-oost`
          }
        }).catch((error) => {
          this.setState({error:"Er ging iets mis tijdens het opslaan."})
        })
      }else{
        this.setState({error:"Verplichte velden"})
      }
    }
  };

  async deleteUpload(): Promise<void> {
    if(!this.state.pdfID) return; // no id, no delete
    if(this.state.pdfID){
      await APIClient.deleteUpload(this.state.pdfID)
      this.fetchUploads();
      this.setState({uploadReceived: false})
    }
  }

  togglePdfReplaceModal(event: any) : void{
    event.preventDefault(); // prevent browser from reloading and posting form
    // save file in state so we can handle it later
    this.file = event ? event.target.files[0] : undefined
    this.setState({pdfReplaceDialogToggle: event ? true : false })
  }
  toggleDeleteDialog(event:any){
    event.preventDefault()
    this.setState({toggleDeleteDialog: true})
  }

  // Preview card for PDF upload + delete button 
  pdfCard(): ReactNode {
    if(this.state.pdf) { // still not happy with styling :(
      return <Card style={{maxWidth:"10vw", marginTop:"12px"}}>
        <Card.Body style={{textAlign:"center", padding:"4px"}}>
          <a target="_blank" rel="noreferrer" href={this.state.pdf} style={{color:"darkgrey", textDecoration:"none"}}>
          <FontAwesomeIcon icon="file-pdf" size="4x" style={{margin:"4px", marginBottom:"8px"}} />
          </a> <br/>
          <Button variant="danger" size="sm" onClick={(event) => { this.toggleDeleteDialog(event) }}><FontAwesomeIcon icon="trash" /> <b>&nbsp;Delete</b> </Button>
        </Card.Body>
      </Card>
    }
    return <></> // return empty fragment if no pdf
  }


  render() {

    let { pdfReplaceDialogToggle, title, error, text, validated, pdf, id, toggleDeleteDialog } = this.state

    return <Card>

      <ModalConfirm title="PDF vervangen" active={pdfReplaceDialogToggle} callback={(val:boolean) => { if(val === true){ this.handleUpload() } else {this.setState({pdfReplaceDialogToggle: false})} }}>Bestaande info-oost vervangen ?</ModalConfirm>
      <ModalConfirm title="PDF vervangen" active={toggleDeleteDialog} callback={(val:boolean) => { if(val === true){ this.deleteUpload() } else {this.setState({toggleDeleteDialog: false})}}}>Wilt U de PDF verwijderen?</ModalConfirm>

      <Card.Header><FontAwesomeIcon icon="file-pdf" /> &nbsp;Info-Oost : {title}</Card.Header>
        <Card.Body>
          
          { validated && <Alert variant='success'>Wijzigingen succesvol opgeslagen!</Alert> }
          { error && <Alert variant='danger'>{error}</Alert> }
          { this.state.uploadReceived && <Alert variant="success"> Uw PDF is sucessvol opgeslagen! </Alert>}

          <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Titel</Form.Label>
              <Form.Control type="title" onChange={this.handleChange} value={title} name="title" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Text (optioneel)</Form.Label>
              <DefaultEditor  name="text" onChange={this.handleChange} value={text} style={{minHeight:"25vh"}} />
            </Form.Group>
            
            { id && <> {/* show upload + form */}
              <Form.Group> 
                <Form.Label>Upload</Form.Label>
                { pdf && <Form.Control type="file" value="" onChange={ e => this.togglePdfReplaceModal(e)}/>  }
                {!pdf && <Form.Control type="file" value="" onChange={ this.handleUpload }/>}
              </Form.Group>
              <Form.Group>
                <Stack direction="horizontal" gap={2}>{this.pdfCard()}</Stack>
              </Form.Group>
            </> }

            <hr/>
            <Button variant="primary" type="submit" onClick={this.handleSubmit}><FontAwesomeIcon icon="floppy-disk" /> <b>&nbsp;Opslaan</b></Button>
          </Form>
        </Card.Body>
    </Card>
  }
}

export default withParams(InfoItem)