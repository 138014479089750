import React from 'react';
import { Card, Alert, Form, Button } from 'react-bootstrap';
import { withParams } from '../helpers';
import API from '../APIClient';
import { UserType } from '../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const APIClient = new API();

interface Parameters { id?: number; }
interface Props { params: Parameters; }
interface State { 
  email?:string
  password?:string
  userType?:UserType
  id?:number
  validated?:boolean;
  error?:string; 
  passwordChanged?: boolean;
}

// let userTypes = ["admin", "editor", "agenda"]

class User extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { email:"", password:"", userType:"admin",validated:undefined }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(){
    if(this.props.params.id){
      APIClient.user(this.props.params.id).then((data) => {
        console.log(data)
        this.setState({ id: data.id, email: data.email, password: data.password, userType: data.userType })
      }).catch((e) => {
        window.location.href = "./login/2"
      })
    } 
  }
  saveUser(userDetails:State) {
    let {email, password, userType} = userDetails
    APIClient.updateUser({email:email as string, password:password as string, userType:userType as UserType})
  }

  handleChange(event:any){
    let value = event.target.value;
    let name = event.target.name;
    if(name || value){
      this.setState({[name]:value})
    }
  }
  handleSubmit(event:any) {

    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    console.log(this.state)
    this.saveUser(this.state)

    this.setState({passwordChanged: true})

  };

  render() {

    return <Card>
      <Card.Header><FontAwesomeIcon icon="user" /> &nbsp;Gebruikersbeheer</Card.Header>
        <Card.Body>
          
          { this.state.validated && <Alert variant='success'>Wijzigingen succesvol opgeslagen!</Alert> }
          { this.state.error && <Alert variant='danger'>{this.state.error}</Alert> }
          { this.state.passwordChanged && <Alert variant='success'>Uw wachtwoord is gewijzigd!</Alert>}

          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={this.handleChange} value={this.state.email} name="email" required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" onChange={this.handleChange} name="password" required />
            </Form.Group>
            <Form.Group>
              <Form.Label>User Type</Form.Label>
              <Form.Select onChange={this.handleChange} value={this.state.userType} name="userType" required>
                  <option value="agenda">agenda</option>
                  <option value="admin">admin</option>
                  <option value="editor">editor</option>
              </Form.Select>
            </Form.Group>
            <br />
            <Button variant="primary" type="submit" onClick={this.handleSubmit}> <FontAwesomeIcon icon="floppy-disk" /> <b>&nbsp;Opslaan</b></Button>
          </Form>

        </Card.Body>
    </Card>
  }
}



export default withParams(User)